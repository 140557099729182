//todo utils
const slicedQuerySelectorAll = (
	selector: string,
	element: HTMLElement | Document = document
) => {
	return [].slice.call(element.querySelectorAll(selector));
};

//todo: polyfillsbe? vendorba? mdderneknek nem kell
// old browsers fix
if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype['msMatchesSelector'] ||
		Element.prototype.webkitMatchesSelector;
}

class Nano {
	private elements: Array<HTMLElement>;
	public length: number;

	constructor(selector: string | object) {
		this.elements = [];

		try {
			if (typeof selector === 'string') {
				this.elements = slicedQuerySelectorAll(selector);
			} else if (typeof selector === 'object') {
				this.elements = Array.isArray(selector) ? selector : [selector];
			}
		} catch(err) {
			console.error(err);
		}

		this.length = this.elements.length;
	}

	find(selector) {
		const foundElements: Array<HTMLElement> = [];

		let found;

		this.each((element: HTMLElement) => {
			found = slicedQuerySelectorAll(selector, element);

			if (found.length) {
				foundElements.push(...found);
			}
		});

		return new Nano(foundElements);
	}

	attr(attribute, value) {
		return this.each(element => element.setAttribute(attribute, value));
	}

	getAttr(value) {
		return this.elements[0].getAttribute(value);
	}

	removeAttr(value) {
		return this.each(element => element.removeAttribute(value));
	}

	animate(time, scale, rotate, rotateX, rotateY, translateX, translateY, skewX, skewY, opacity) {
		return this.each(element => {
			element.style.cssText = element.style.cssText + 'transition: all ' + time +
				's ease-in-out; transform: scale(' + scale + ') rotate(' + rotate + 'deg) rotateX(' +
				rotateX + 'deg) rotateY(' + rotateY + 'deg) translate(' + translateX + 'px, ' +
				translateY + 'px) skew(' + skewX + 'deg, ' + skewY + 'deg); opacity:'+opacity+';'
		});
	}

	closest(selector: string) {
		let element = this.elements[0],
			closestElement: HTMLElement|Array<HTMLElement> = [];

		while (element) {
			element = element.parentElement;

			if (element.matches(selector)) {
				closestElement = element;

				break;
			}
		}

		return new Nano(closestElement);
	}

	css(values: Object) {
		return this.each(element => {
			for (const [key, value] of Object.entries(values)) {
				element.style[key] = value;
			}
		});
	}

	parent() {
		return new Nano(
			this.elements.map((element: HTMLElement) => element.parentNode )
		);
	}

	data(key?: string, value?: any) {
		const allData = this.elements[0].dataset;

		if (key) {
			if (value) {
				allData[key] = value;

				return this;
			}

			return allData[key];
		}

		return allData;
	}

	eq(index) {
		this.elements = [this.elements[index]];
		return this;
	}

	each(fn) {
		[].forEach.call(this.elements, fn);
		return this;
	}

	//todo filter
	//todo toggle

	get() {
		return this.elements[0];
	}

	show() {
		return this.each((element: HTMLElement) => element.style.display = 'block');
	}

	hide() {
		return this.each((element: HTMLElement) => element.style.display = 'none');
	}

	_onDelegated(type, selector, fn) {
		return this.each((element) => {
			element.addEventListener(
				type,
				(...args) => {
					const [ev] 				= args,
						  delegatedElement 	= element.querySelector(selector);

					//todo szar az egész, Cash, jQ h csinálja?
					//todo: talán querySelectorAll-t és megnézni h benne van-e?
					if (delegatedElement === ev.target || delegatedElement.contains(ev.target)) {
						fn.apply(this, args);
					}
				},
				false
			);
		});
	}

	//todo off is kéne
	on(type: string, _selector: string|Function, _fn?: Function) {
		if (_fn) {
			return this._onDelegated(type, _selector, _fn);
		} else {
			const fn = _selector;

			return this.each(element => element.addEventListener(type, fn, false));
		}
	}

	one(type, fn) {
		return this.each(element => {
			element.addEventListener(type, fn, {
				once   : true,
				passive: true
			});
		});
	}

	prev() {
		return new Nano(
			this.elements.map((element: HTMLElement) => element.previousSibling)
		);
	}

	hasClass(value) {
		return this.elements[0].classList.contains(value);
	}

	toggleClass(...args) {
		return this.each(element => element.classList.toggle(...args));
	}

	addClass(value: string) {
		return this.each(element => element.classList.add(...value.split(' ')));
	}

	removeClass(value: string) {
		return this.each(element => element.classList.remove(...value.split(' ')));
	}

	empty() {
		return this.html('');
	}

	html(value) {
		return this.each(element => element.innerHTML = value);
	}

	text(value) {
		return this.each(element => element.innerText = value);
	}

	insertBefore(value) {
		return this.each(element => element.insertAdjacentHTML('beforeBegin', value));
	}

	insertAfter(value) {
		return this.each(element => element.insertAdjacentHTML('afterEnd', value));
	}

	insertFirst(value) {
		return this.each(element => element.insertAdjacentHTML('afterBegin', value));
	}

	insertLast(value) {
		return this.each(element => element.insertAdjacentHTML('beforeEnd', value));
	}

	val(value?: string) {
		const firstElement = <HTMLInputElement>this.elements[0];

		if (typeof value !== 'undefined') {
			firstElement.value = value;

			return this;
		}

		return firstElement.value;
	}
}

const $ = function(selector: string): Nano {
	return new Nano(selector);
};

export default $;